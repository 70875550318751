import React from 'react';

interface EmailListSignupProps {

}
//<link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css" />
//<style type="text/css">
//	#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; width:100%;}
//	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
//	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
//</style>

function EmailListSignup({ }: EmailListSignupProps) {
	return <>
		<h3>Get updates in your email</h3>
		<label>If you're interested occasionally getting exclusive information, sign up below.</label>
		<div id="mc_embed_signup">
			<form action="https://ifelseconsulting.us19.list-manage.com/subscribe/post?u=b622190305823330f30d74b10&amp;id=ec52b48ea6" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
				<div id="mc_embed_signup_scroll">
					<input type="email" defaultValue="" name="EMAIL" className="email" id="mce-EMAIL" placeholder="Email Address" required />
					<div style={{ position: 'absolute', left: -5000 }}>
						<input type="text" name="b_b622190305823330f30d74b10_ec52b48ea6" tabIndex={-1} defaultValue="" />
					</div>
					<div className="clear">
						<input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
					</div>
				</div>
			</form>
		</div>
	</>
}

export default EmailListSignup