import React from 'react'
import {
	FacebookShareButton,
	FacebookIcon,
	LinkedinShareButton,
	LinkedinIcon,
	TwitterShareButton,
	TwitterIcon,
	RedditShareButton,
	RedditIcon,
	EmailIcon,
	EmailShareButton
} from 'react-share'

interface ShareButtonsProps {
	twitterHandle: string;
	url: string;
	title: string;
	tags: string[];
}

export const ShareButtons = ({ twitterHandle, url, title, tags }: ShareButtonsProps) => (
	<div>
		<FacebookShareButton url={url}>
			<FacebookIcon />
		</FacebookShareButton>

		<TwitterShareButton url={url} title={title} hashtags={tags}>
			<TwitterIcon />
		</TwitterShareButton>

		<LinkedinShareButton url={url}>
			<LinkedinIcon />
		</LinkedinShareButton>

		<RedditShareButton url={url} title={title}>
			<RedditIcon />
		</RedditShareButton>

		<EmailShareButton url={url} title={title}>
			<EmailIcon />
		</EmailShareButton>
	</div>
)

export default ShareButtons;