import React from 'react';
import MinorParagraph from './MinorParagraph';
import EmailListSignup from './EmailListSignup';
import MarkdownComponent from './MarkdownComponent';

interface MoreInfoProps {

}

function MoreInfo({ }: MoreInfoProps) {
	return <>
		<MinorParagraph><MarkdownComponent slug="salespitch" /></MinorParagraph>
		<EmailListSignup />
	</>;
}

export default MoreInfo