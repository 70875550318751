import React from 'react';

interface TimeToReadProps {
	timeToRead: number;
}

function TimeToRead({ timeToRead }: TimeToReadProps) {
	return <>Time to read: {timeToRead} minute{timeToRead > 1 ? 's' : ''}</>
}

export default TimeToRead