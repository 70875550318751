import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Divider from "../components/Divider"
import MinorParagraph from "../components/MinorParagraph"
import TimeToRead from "../components/TimeToRead"
import MoreInfo from "../components/MoreInfo"
import ShareButtons from "../components/ShareButtons"

interface Props {
	data: {
		mdx: {
			fields: {
				slug: string
			}
			frontmatter: {
				title: string;
				description: string | null;
				date: string;
				image: {
					childImageSharp: {
						fixed: {
							src: string;
						}
					}
				} | null;
			},
			excerpt: string;
			body: string;
			timeToRead: number;
		}
		site: {
			siteMetadata: {
				title: string
				siteUrl: string
				social: {
					twitter: string
				}
			}
		}
	}
	pageContext: any;
	location: any;
}

const BlogPostTemplate = ({ data, pageContext, location }: Props) => {
	const post = data.mdx
	const site = data.site.siteMetadata
	const { previous, next } = pageContext

	return (
		<Layout>
			<SEO
				title={post.frontmatter.title}
				description={post.frontmatter.description || post.excerpt}
				type="article"
				image={post.frontmatter.image?.childImageSharp.fixed.src}
			/>
			<article>
				<header>
					<h1 style={{ marginTop: rhythm(1), marginBottom: 0, }} >
						{post.frontmatter.title}
					</h1>
					<MinorParagraph style={{ ...scale(-1 / 5), marginBottom: rhythm(1) }}><TimeToRead timeToRead={post.timeToRead} /></MinorParagraph>
				</header>
				<MDXRenderer>{post.body}</MDXRenderer>
				<ShareButtons twitterHandle={site.social.twitter} title={post.frontmatter.title} tags={[]} url={`${site.siteUrl}${post.fields.slug}`} />
			</article>

			<nav>
				<ul
					style={{
						display: `flex`,
						flexWrap: `wrap`,
						justifyContent: `space-between`,
						listStyle: `none`,
						padding: 0,
					}}
				>
					<li>
						{previous && (
							<Link to={previous.fields.slug} rel="prev">
								← {previous.frontmatter.title}
							</Link>
						)}
					</li>
					<li>
						{next && (
							<Link to={next.fields.slug} rel="next">
								{next.frontmatter.title} →
							</Link>
						)}
					</li>
				</ul>
			</nav>
			<Divider />
			<footer>
				<MoreInfo />
			</footer>
		</Layout>
	)
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
		title
		siteUrl
		social {
			twitter
		}
      }
    }
    mdx(fields: { slug: { eq: $slug } }, collection: { eq: "blog" }) {
      id
      excerpt(pruneLength: 160)
	  body
	  timeToRead
	  fields {
		  slug
	  }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
		description
		image {
			childImageSharp {
				fixed {
					src
				}
			}
		}
	  }
    }
  }
`